import $ from "jquery";
import "slick-carousel";

$(function () {
    /*--------------------------------------------------------------
    ## Page Home - Banner
    --------------------------------------------------------------*/
    $(".js-home-banner").slick({
        dots: true,
        arrows: false,
    });

    $(".js-home-banner").on("init", function () {
        AOS.refresh();
    });

    /*--------------------------------------------------------------
    ## Page Home - QuickAccess
    --------------------------------------------------------------*/
    $(".js-home-quickaccess").slick({
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
});
